import React, { useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import './novaconta.css';

import firebase from '../Config/firebase';
import 'firebase/auth';

function NovaConta() {
  var ano = new Date().getFullYear();
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [voltarParaTelaInicial, setVoltarParaTelaInicial] = useState(false);

  // Use useHistory para acessar o objeto 'history'
  const history = useHistory();

  async function cadastrarUsuario() {
    setMensagem('');

    if (!email || !senha) {
      setMensagem('Informe todos os campos');
      return;
    }

    try {
      await firebase.auth().createUserWithEmailAndPassword(email, senha);

      // Salve o email no localStorage
      localStorage.setItem('emailSalvo', email);

      const db = firebase.firestore();

      // Crie um documento na coleção "usuarios" com o email como ID
      await db.collection('usuarios').doc(email).set({
        email: email,
        permissao: 'remove', // Define a permissão como 'remove'
      });

      // Redirecione o usuário após o registro bem-sucedido
      history.push('/app');
    } catch (error) {
      if (error.message === 'Password should be at least 6 characters') {
        setMensagem('A senha deve ter pelo menos 6 caracteres');
      } else if (error.message === 'The email address is badly formatted.') {
        setMensagem('O email não é válido');
      } else if (
        error.message ===
        'The email address is already in use by another account.'
      ) {
        setMensagem('Esse email já está em uso por outra conta');
      } else {
        setMensagem('Erro ao criar conta: ' + error.message);
      }
    }
  }

  function voltarParaTelaInicialHandler() {
    setVoltarParaTelaInicial(true);
  }

  if (voltarParaTelaInicial) {
    return <Redirect to="/" />;
  }

  return (
    <div className="d-flex align-items-center text-center form-container">
      <button className="btn float-right mt-2 btn-close" onClick={voltarParaTelaInicialHandler}>
        X
      </button>
      <form className="form-signin">
        <img
          className="mb-4 imglogo"
          src="/Images/logo_prime_small.png"
          alt=""
        />
        <h1 className="h3 mb-3 fw-normal">Criar Conta</h1>

        <div className="form-floating">
          <input
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            className="form-control"
            id="floatingInput"
            placeholder="E-mail"
          />
          <label htmlFor="floatingInput">E-mail</label>
        </div>

        <div className="form-floating">
          <input
            onChange={(e) => setSenha(e.target.value)}
            type="password"
            className="form-control"
            id="floatingPassword"
            placeholder="Senha"
          />
          <label htmlFor="floatingPassword">Senha</label>
        </div>

        <button
          onClick={cadastrarUsuario}
          className="w-100 btn btn-lg btn-primary"
          type="button"
        >
          Criar conta
        </button>

        {mensagem.length > 0 ? (
          <div className="alert alert-danger mt-2" role="alert">
            {mensagem}
          </div>
        ) : null}

        <div className="login-links mt-5">
          <Link to="/app" className="mx-3">
            Já tenho uma conta.
          </Link>
        </div>

        <p className="mt-5 mb-3 text-muted">
          &copy; Desenvolvido por Prime Control - {ano}
        </p>
      </form>
    </div>
  );
}

export default NovaConta;
