import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./listacliente.css";
import firebase from "../../Config/firebase";
import * as XLSX from "xlsx";

function ListaClientes(props) {
  const [clientes, setClientes] = useState([]);
  const [abaAtiva, setAbaAtiva] = useState("clientes");
  const [nome, setNome] = useState("");
  const [fone, setTelefone] = useState("");
  const [email, setEmail] = useState("");
  const [cep, setCep] = useState("");
  const [endereco, setEndereco] = useState(""); // Alterei o nome desta variável
  const [numeroResidencia, setNumeroResidencia] = useState("");
  const [pais, setPais] = useState("");
  const [genero, setGenero] = useState("");
  const [ferramentas, setFerramentas] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const [xlsxContent, setXlsxContent] = useState(null);
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    setIsButtonDisabled(
      !(
        nome &&
        fone &&
        email &&
        cep &&
        numeroResidencia &&
        endereco &&
        pais &&
        genero &&
        ferramentas.length > 0 &&
        selectedImage
      )
    );
  }, [
    nome,
    fone,
    email,
    cep,
    numeroResidencia,
    endereco,
    pais,
    genero,
    ferramentas,
    selectedImage,
  ]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (
      nome &&
      fone &&
      email &&
      cep &&
      numeroResidencia &&
      endereco &&
      pais &&
      genero &&
      ferramentas.length > 0 &&
      selectedImage
    ) {
      try {
        const storageRef = firebase.storage().ref();
        const imageRef = storageRef.child(selectedImage.name);
        await imageRef.put(selectedImage);

        const imageUrl = await imageRef.getDownloadURL();

        const novoCliente = {
          nome: nome,
          fone: fone,
          email: email,
          fotoPerfil: imageUrl,
        };

        await firebase.firestore().collection("clientes").add(novoCliente);

        setAbaAtiva("clientes");
        setClientes((clientesAntigos) => [...clientesAntigos, novoCliente]);
        window.location.reload();
      } catch (error) {
        console.error("Erro ao cadastrar cliente:", error);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await firebase
          .firestore()
          .collection("clientes")
          .get();
        const fetchedClientes = response.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setClientes(fetchedClientes);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };

    fetchData();
  }, []);

  const urlToDownload = `${process.env.PUBLIC_URL}/Documents`;
  const handleDownloadXML = (xmlName) => {
    const link = document.createElement("a");
    link.href = `${urlToDownload}/${xmlName}.xml`;
    link.target = "_blank";
    link.download = `${xmlName}.xml`;
    link.click();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        setShowModal(true);

        const workbook = XLSX.read(content, { type: "binary" });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        const data = XLSX.utils.sheet_to_json(sheet);

        setXlsxContent(data);
      };
      reader.readAsBinaryString(file);
    }
  };

  const handleSalvarDocumentos = (e) => {
    e.preventDefault();

    const dadosDocumentos = {
      cnpjDeclarante: document.getElementById("cnpjDeclarante").value,
      tipoDeclarado: document.getElementById("tipoDeclarado").value,
      niDeclarado: document.getElementById("niDeclarado").value,
      paisEmissaoNIF: document.getElementById("paisEmissaoNIF").value,
      nomeDeclarado: document.getElementById("nomeDeclarado").value,
      nomePJ: document.getElementById("nomePJ").value,
      enderecoDocumento: document.getElementById("endereco").value,
    paisDocumento: "PE",
      titulo: document.getElementById("titulo").value,
      primeiroNome: document.getElementById("primeiroNome").value,
      nomeMeio: document.getElementById("nomeMeio").value,
      prefixo: document.getElementById("prefixo").value,
      ultimoNome: document.getElementById("ultimoNome").value,
    };

    setModalData(dadosDocumentos);
  };

  return (
    <div>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <a
            className={`nav-link ${abaAtiva === "clientes" ? "active" : ""}`}
            onClick={() => setAbaAtiva("clientes")}
          >
            Clientes
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${abaAtiva === "perfil" ? "active" : ""}`}
            onClick={() => setAbaAtiva("perfil")}
          >
            Cadastrar Cliente
          </a>
        </li>
        <li className="nav-item">
          {" "}
          {/* Adicione a aba "Documentos" */}
          <a
            className={`nav-link ${abaAtiva === "documentos" ? "active" : ""}`}
            onClick={() => setAbaAtiva("documentos")}
          >
            Fiscal
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${abaAtiva === "arquivos" ? "active" : ""}`}
            onClick={() => setAbaAtiva("arquivos")}
          >
            Gerenciador de Arquivos
          </a>
        </li>
      </ul>

      {abaAtiva === "perfil" ? (
        <div className="perfil-form">
          <form onSubmit={handleFormSubmit}>
            <div className="form-group">
              <label>Nome completo:</label>
              <div className="row">
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-3">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => setSelectedImage(e.target.files[0])}
                    style={{ display: "none" }}
                    id="image-upload"
                  />
                  <label htmlFor="image-upload" className="image-upload-label">
                    {selectedImage ? (
                      <img
                        src={URL.createObjectURL(selectedImage)}
                        alt="Selected"
                        className="selected-image-preview"
                      />
                    ) : (
                      <span className="add-image-button">Add Image</span>
                    )}
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col">
                  <label>Telefone:</label>
                  <input
                    type="tel"
                    value={fone}
                    onChange={(e) => setTelefone(e.target.value)}
                    pattern="[0-9]+"
                    className="form-control"
                    required
                  />
                </div>
                <div className="col">
                  <label>E-mail:</label>
                  <input
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col">
                  <label>CEP:</label>
                  <input
                    type="text"
                    value={cep}
                    onChange={(e) => setCep(e.target.value)}
                    pattern="[0-9]{5}-[0-9]{3}"
                    className="form-control"
                    required
                  />
                </div>
                <div className="col">
                  <label>Número da residência:</label>
                  <input
                    type="text"
                    value={numeroResidencia}
                    onChange={(e) => setNumeroResidencia(e.target.value)}
                    pattern="[0-9]+"
                    className="form-control"
                    style={{ width: "50%" }}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col">
                  <label>Endereço:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={endereco}
                    onChange={(e) => setEndereco(e.target.value)}
                    required
                  />
                </div>
                <div className="col">
                  <label>Complemento:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={pais}
                    onChange={(e) => setPais(e.target.value)}
                  />
                </div>
                <div className="col">
                  <label>País:</label>
                  <select className="form-control" required>
                    <option value="">Selecione</option>
                    <option value="br">Brasil</option>
                    <option value="us">Estados Unidos</option>
                    <option value="ca">Canadá</option>
                    <option value="mx">México</option>
                    <option value="gb">Reino Unido</option>
                    <option value="fr">França</option>
                    <option value="de">Alemanha</option>
                    <option value="it">Itália</option>
                    <option value="es">Espanha</option>
                    <option value="jp">Japão</option>
                    <option value="au">Austrália</option>
                    <option value="cn">China</option>
                    <option value="in">Índia</option>
                    <option value="ru">Rússia</option>
                    <option value="za">África do Sul</option>
                    <option value="ar">Argentina</option>
                    <option value="cl">Chile</option>
                    <option value="co">Colômbia</option>
                    <option value="pe">Peru</option>
                    <option value="nz">Nova Zelândia</option>
                    <option value="ch">Suíça</option>
                    <option value="se">Suécia</option>
                    <option value="no">Noruega</option>
                    <option value="dk">Dinamarca</option>
                    <option value="nl">Holanda</option>
                    <option value="be">Bélgica</option>
                    <option value="at">Áustria</option>
                    <option value="fi">Finlândia</option>
                    <option value="pt">Portugal</option>
                    <option value="ie">Irlanda</option>
                    <option value="gr">Grécia</option>
                  </select>
                </div>
              </div>
            </div>
            <div
              className="form-group"
              onChange={(e) => setGenero(e.target.value)}
            >
              <label>Selecione o seu gênero:</label>
              <div>
                <label>
                  <input
                    type="radio"
                    name="genero"
                    value="masculino"
                    required
                  />{" "}
                  Masculino
                </label>
                <br />
                <label>
                  <input type="radio" name="genero" value="feminino" /> Feminino
                </label>
                <br />
                <label>
                  <input type="radio" name="genero" value="outros" /> Outros
                </label>
              </div>
            </div>
            <div
              className="form-group"
              onChange={(e) => setFerramentas(e.target.value)}
            >
              <label>Selecione as ferramentas que você conhece:</label>
              <div>
                <label>
                  <input type="checkbox" name="ferramenta" value="robot" />{" "}
                  Robot Framework
                </label>
                <br />
                <label>
                  <input type="checkbox" name="ferramenta" value="selenium" />{" "}
                  Selenium WebDriver
                </label>
                <br />
                <label>
                  <input type="checkbox" name="ferramenta" value="cypress" />{" "}
                  Cypress
                </label>
                <br />
                <label>
                  <input type="checkbox" name="ferramenta" value="appium" />{" "}
                  Appium
                </label>
                <br />
                <label>
                  <input type="checkbox" name="ferramenta" value="protractor" />{" "}
                  Protractor
                </label>
              </div>
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-primary salvar"
                disabled={isButtonDisabled}
              >
                Salvar
              </button>
            </div>
          </form>
        </div>
      ) : abaAtiva === "clientes" ? (
        <table className="table table-hover table-bordered">
          <thead>
            <tr className="table-secondary">
              <th scope="col">Código</th>
              <th scope="col">Nome</th>
              <th scope="col">E-mail</th>
              <th scope="col">Telefone</th>
              <th scope="col" className="col-acao text-center">
                Ação
              </th>
            </tr>
          </thead>
          <tbody>
            {clientes.map((cliente) => (
              <tr key={cliente.id}>
                <th scope="row">{cliente.id}</th>
                <td>{cliente.nome || "Nome não disponível"}</td>
                <td>{cliente.email}</td>
                <td>{cliente.fone}</td>
                <td>
                  <Link to={"/app/editarcliente/" + cliente.id}>
                    <i className="fas fa-edit icone-acao"></i>
                  </Link>
                  <Link to="#" onClick={() => props.clickDelete(cliente.id)}>
                    <i className="far fa-trash-alt icone-acao red"></i>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
          <br />
          {/* <div className="col-4 btn-novo">
            <Link
              to="/app/novocliente"
              className="btn btn-primary"
              type="button"
            >
              Novo Cliente
            </Link>
          </div> */}
        </table>
      ) : abaAtiva === "documentos" ? (
        <div className="documentos">
          <form>
            <div className="form-group">
              <button
                type="button"
                className="btn btn-primary btn-xml"
                onClick={() => handleDownloadXML("cliente")}
              >
                Baixar XML
              </button>
              <br />
              <div className="form-group">
                <label htmlFor="cnpjDeclarante">CNPJ Declarante:</label>
                <input
                  type="text"
                  pattern="[0-9]{14}"
                  className="form-control"
                  id="cnpjDeclarante"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="tipoDeclarado">Tipo Declarado:</label>
                <input
                  type="text"
                  className="form-control"
                  id="tipoDeclarado"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="niDeclarado">NI Declarado:</label>
                <input
                  type="text"
                  pattern="[0-9]{14}"
                  className="form-control"
                  id="niDeclarado"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="paisEmissaoNIF">País Emissão NIF:</label>
                <input
                  type="text"
                  pattern="[A-Za-z]{2}"
                  className="form-control"
                  id="paisEmissaoNIF"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="nomeDeclarado">Nome Declarado:</label>
                <input
                  type="text"
                  className="form-control"
                  id="nomeDeclarado"
                  required
                  maxLength={100}
                />
              </div>
              <div className="form-group">
                <label htmlFor="nomePJ">Nome PJ:</label>
                <input
                  type="text"
                  className="form-control"
                  id="nomePJ"
                  required
                  maxLength={100}
                />
              </div>
              <div className="form-group">
                <label htmlFor="endereco">Endereço:</label>
                <input
                  type="text"
                  className="form-control"
                  id="endereco"
                  required
                  maxLength={200}
                />
              </div>
              <div className="form-group">
                <label htmlFor="pais">País:</label>
                <input
                  type="text"
                  pattern="[A-Za-z]{2}"
                  className="form-control"
                  id="pais"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="titulo">Título:</label>
                <input
                  type="text"
                  className="form-control"
                  id="titulo"
                  required
                  maxLength={10}
                />
              </div>
              <div className="form-group">
                <label htmlFor="primeiroNome">Primeiro Nome:</label>
                <input
                  type="text"
                  className="form-control"
                  id="primeiroNome"
                  required
                  maxLength={10}
                />
              </div>
              <div className="form-group">
                <label htmlFor="nomeMeio">Nome do Meio:</label>
                <input
                  type="text"
                  className="form-control"
                  id="nomeMeio"
                  required
                  maxLength={10}
                />
              </div>
              <div className="form-group">
                <label htmlFor="prefixo">Prefixo:</label>
                <input
                  type="text"
                  className="form-control"
                  id="prefixo"
                  maxLength={5}
                />
              </div>
              <div className="form-group">
                <label htmlFor="ultimoNome">Último Nome:</label>
                <input
                  type="text"
                  className="form-control"
                  id="ultimoNome"
                  required
                  maxLength={10}
                />
              </div>
            </div>
            <div className="form-group">
              <button
                type="button"
                className="btn btn-primary btn-xml"
                onClick={handleSalvarDocumentos}
              >
                Salvar
              </button>
            </div>

            {modalData && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => setModalData(null)}>
                    &times;
                  </span>
                  <h2>Informações Fiscais</h2>
                  <pre>{JSON.stringify(modalData, null, 2)}</pre>
                </div>
              </div>
            )}
          </form>
        </div>
      ) : abaAtiva === "arquivos" ? (
        <div className="arquivos">
          <form>
            <br />
            <div className="form-group">
              <label>Importar Arquivo XLSX/XLS:</label>
              <input
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
                className="form-control"
              />
            </div>
          </form>
          {/* Modal para exibir o conteúdo do arquivo */}
          {showModal && (
            <div className="modal">
              <div className="modal-content">
                <span className="close" onClick={() => setShowModal(false)}>
                  &times;
                </span>
                <h2>Conteúdo do Arquivo Importado</h2>
                <pre>{JSON.stringify(xlsxContent, null, 2)}</pre>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
}

export default ListaClientes;
