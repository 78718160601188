import React, { useContext, useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { AuthContext } from "../../Context/auth";
import firebase from "../../Config/firebase";

import Home from '../../Home/home';
import Login from '../../Login/login';

function PrivateRoute({ component: Component, ...rest }) {
  const { logado } = useContext(AuthContext);
  const [permissao, setPermissao] = useState(null);
  const db = firebase.firestore();

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((authUser) => {
      if (authUser) {
        db.collection("usuarios")
  .doc(authUser.email)
  .get()
  .then((doc) => {
    if (doc.exists) {
      const permissao = doc.data().permissao;
      setPermissao(permissao);
    } else {
      console.log("Documento de usuário não encontrado.");
      setPermissao(null);
    }
  })
  .catch((error) => {
    console.error("Erro ao verificar a permissão do usuário:", error);
  });
      } else {
        console.log('O usuário não está autenticado')
      }
    });

    return () => unsubscribe();
  }, [db]);

  if (logado) {
    if (permissao === "admin") {
      return <Route {...rest} component={Component} />;
    } else {
      return <Route to="/app/home" component={Home} />;
    }
  } else {
    return <Route to="/app" component={Login} />;
  }
}

export default PrivateRoute;
