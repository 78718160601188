import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Navbar from '../Components/Navbar/navbar';
import './editarcliente.css';
import firebase from '../Config/firebase';
import 'firebase/firestore';

function EditarCliente(props) {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [fone, setFone] = useState('');
  const [fotoClienteUrl, setFotoClienteUrl] = useState('');
  const [fotoClienteFile, setFotoClienteFile] = useState(null);
  const [mensagem, setMensagem] = useState('');
  const [sucesso, setSucesso] = useState('N');
  const db = firebase.firestore();

  useEffect(() => {
    const clienteRef = db.collection('clientes').doc(props.match.params.id);

    clienteRef.get().then((resultado) => {
      const data = resultado.data();
      setNome(data.nome);
      setEmail(data.email);
      setFone(data.fone);
      setFotoClienteUrl(data.fotoPerfil);
    });
  }, [props.match.params.id, db]);

  function AlterarCliente() {
    if (nome.length === 0) {
      setMensagem('Informe o nome');
    } else if (email.length === 0) {
      setMensagem('Informe o e-mail');
    } else {
      const clienteRef = db.collection('clientes').doc(props.match.params.id);

      const updateData = {
        nome: nome,
        email: email,
        fone: fone
      };

      if (fotoClienteFile) {
        // Se um novo arquivo de foto foi selecionado, atualizar a foto no Firebase Storage
        const storageRef = firebase.storage().ref();
        const imageRef = storageRef.child(fotoClienteFile.name);

        imageRef.put(fotoClienteFile).then(() => {
          imageRef.getDownloadURL().then((url) => {
            updateData.fotoPerfil = url;

            clienteRef.update(updateData).then(() => {
              setMensagem('');
              setSucesso('S');
            }).catch((erro) => {
              setMensagem(erro);
              setSucesso('N');
            });
          });
        });
      } else {
        // Se nenhum novo arquivo de foto foi selecionado, apenas atualizar os outros dados
        clienteRef.update(updateData).then(() => {
          setMensagem('');
          setSucesso('S');
        }).catch((erro) => {
          setMensagem(erro);
          setSucesso('N');
        });
      }
    }
  }

  function ExcluirFotoCliente() {
    const clienteRef = db.collection('clientes').doc(props.match.params.id);

    const storageRef = firebase.storage().refFromURL(fotoClienteUrl);
    storageRef.delete().then(() => {
      clienteRef.update({ fotoPerfil: null }).then(() => {
        setFotoClienteUrl('');
      });
    });
  }

  return (
    <div>
      <Navbar />
      <div className="container-fluid titulo">
        <div className="offset-lg-3 col-lg-6">
          <h1>Editar Cliente</h1>
          <form>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">Código</label>
              <input type="text" value={props.match.params.id} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled />
            </div>

            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">Nome</label>
              <input onChange={(e) => setNome(e.target.value)} value={nome} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>

            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">E-mail</label>
              <input onChange={(e) => setEmail(e.target.value)} value={email} type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>

            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">Fone</label>
              <input onChange={(e) => setFone(e.target.value)} value={fone} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>

            <div className="mb-3">
              <label htmlFor="formFile" className="form-label">Foto</label>
              <div className="d-flex align-items-center">
                <input className="form-control" type="file" id="formFile" accept="image/*" onChange={(e) => setFotoClienteFile(e.target.files[0])} />
              </div>
              <br/>
                {fotoClienteUrl && (
                  <div className="mr-2">
                    <img src={fotoClienteUrl} alt={nome} className="edit-cliente-image" />
                  </div>
                )}
                {fotoClienteUrl && (
                  <button type="button" className="btn btn-danger ml-2" onClick={ExcluirFotoCliente}>Excluir Foto</button>
                )}
            </div>

            <div className="text-center buttons">
              <Link to="/app/home" className="btn btn-outline-primary btn-acao">Cancelar</Link>
              <button onClick={AlterarCliente} type="button" className="btn btn-primary btn-acao">Salvar</button>
            </div>

            {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
            {sucesso === 'S' ? <Redirect to='/app/home' /> : null}
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditarCliente;
