import React from 'react';

function Banner(){
    return <section id="banner">
        <div className="container">
            <div className="row">

                <div className="col-lg-6">
                    <h1>Uma plataforma de desafio técnico para testes automatizados.</h1>
                    <h4>Estamos ansiosos para ver seu trabalho em ação!</h4>
                    <a href="/app/novaconta" className="btn btn-dark btn-lg btn-banner">Criar uma conta</a>
                    <a href="/app" className="btn btn-outline-light btn-lg btn-banner">Fazer Login</a>
                </div>

                <div className="col-lg-6">
                    <img src="Images/img_decoration_1.png" alt="Prime Control" />                        
                </div>
       
            </div>
        </div>
    </section>;
  }

export default Banner;