import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar/navbar";
import ListaClientes from "../Components/ListaCliente/listacliente";
import "./home.css";

import firebase from "../Config/firebase";
import "firebase/firestore";

import SweetAlert from "react-bootstrap-sweetalert";

function Home() {
  const [clientes, setClientes] = useState([]);
  const [busca, setBusca] = useState("");
  const [confirmacao, setConfirmacao] = useState(false);
  const [confirmacaoId, setConfirmacaoId] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalCliente, setModalCliente] = useState({});
  const [erroPesquisa, setErroPesquisa] = useState(false);
  const [fotoClienteUrl, setFotoClienteUrl] = useState("");

  useEffect(() => {
    if (busca.trim() !== "") {
      const buscarClientes = async () => {
        const snapshot = await firebase
          .firestore()
          .collection("clientes")
          .where("nome", "==", busca)
          .get();

        const listaCli = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setClientes(listaCli);
      };

      buscarClientes();
    }
  }, [busca]);

  function handlePesquisar() {
    const clienteEncontrado = clientes.find(
      (cliente) => cliente.nome === busca
    );
    if (clienteEncontrado) {
      setModalCliente(clienteEncontrado);
      setModalIsOpen(true);
      setErroPesquisa(false);

      setFotoClienteUrl(
        clienteEncontrado.fotoPerfil || "/Images/perfil.png"
      );
    } else {
      setModalIsOpen(false);
      setErroPesquisa(true);
    }
  }

  function deleteUser(id) {
    firebase
      .firestore()
      .collection("clientes")
      .doc(id)
      .delete()
      .then(() => {
        setConfirmacao(false);
        window.location.reload();
      });
  }

  function confirmDeleteUser(id) {
    setConfirmacaoId(id);
    setConfirmacao(true);
  }

  return (
    <div>
      <Navbar />
      <div className="container-fluid titulo">
        <h1>Gestão de Clientes</h1>

        <div className="row">
          <div className="col-8">
            <div className="input-group mb-3">
              <input
                onChange={(e) => setBusca(e.target.value)}
                type="text"
                className="form-control inputSearch"
                placeholder="Pesquisar por nome"
                aria-describedby="button-addon2"
              />
              <button
                onClick={handlePesquisar}
                className="btn btn-primary"
                type="button"
                id="button-addon2"
              >
                <i className="fas fa-search"></i> Pesquisar
              </button>
            </div>
          </div>

          {erroPesquisa && (
            <div className="alert alert-danger" role="alert">
              Cliente não encontrado.
            </div>
          )}

          <ListaClientes
            arrayClientes={clientes}
            clickDelete={confirmDeleteUser}
          />

          {confirmacao ? (
            <SweetAlert
              warning
              showCancel
              showCloseButton
              confirmBtnText="Sim"
              confirmBtnBsStyle="danger"
              cancelBtnText="Não"
              cancelBtnBsStyle="light"
              title="Exclusão"
              onConfirm={() => {
                deleteUser(confirmacaoId);
              }}
              onCancel={() => setConfirmacao(false)}
              reverseButtons={true}
            >
              Deseja excluir o cliente selecionado?
            </SweetAlert>
          ) : null}

          {modalIsOpen && (
            <div className="modal">
              <div className="modal_bory">
                <div className="modal-content-cliente">
                  <span className="close" onClick={() => setModalIsOpen(false)}>
                    &times;
                  </span>
                  <h2 className="titleModal">Dados do Cliente</h2>
                  {fotoClienteUrl ? (
                    <img
                      src={fotoClienteUrl}
                      alt={modalCliente.nome}
                      className="foto-cliente-modal"
                    />
                  ) : (
                    <img
                      src="/Images/perfil.png"
                      alt="Avatar"
                      className="foto-cliente-modal"
                    />
                  )}
                  <div className="dados_cliente">
                    <p>Nome: {modalCliente.nome}</p>
                    <p>E-mail: {modalCliente.email}</p>
                    <p>Telefone: {modalCliente.fone}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
