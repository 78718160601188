import React from 'react';

function Sobre(){
    return <section id="sobre">
        <div className="container">

        <div id="carouselExampleInterval" className="carousel carousel-dark  slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="5000">
                        <h2>A Qualidade define os Resultados</h2>
                        <h3>
                            Na Prime Control temos uma máxima: a qualidade define os resultados. 
                            Em todas as coisas, o nível de qualidade está diretamente relacionado 
                            ao nível de sucesso. Bons produtos e boas estratégias sempre prosperam. 
                            Somos defensores da qualidade! Nossa meta é mudar a cultura dos clientes 
                            e mostrar opções inteligentes para fazer mais e melhor.
                        </h3>
                    </div>      
                    <div className="carousel-item" data-bs-interval="5000">
                        <h2>Vamos fazer do mundo digital um lugar melhor!</h2>
                        <h3>
                            A Prime Control nasceu com o propósito de fazer do mundo digital um lugar melhor. 
                            O digital cresceu tanto que é difícil imaginar nossas vidas sem Internet, apps, 
                            ecommerce, mídias sociais, bancos digitais e mais uma série de coisas incríveis, 
                            não é mesmo? Então, melhorar o mundo digital significa melhorar uma parte importante 
                            da vida das pessoas.
                        </h3>  
                    </div>      

                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        
        </div>
    </section>;
  }

export default Sobre;