import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCUUS83Lrw4y1pbUSB37i0y8yPt2l48gw0",
  authDomain: "pagina-teste-prime.firebaseapp.com",
  projectId: "pagina-teste-prime",
  storageBucket: "pagina-teste-prime.appspot.com",
  messagingSenderId: "672066389456",
  appId: "1:672066389456:web:fbdfa6837f045d0d7265d5"
};

export default firebase.initializeApp(firebaseConfig);
