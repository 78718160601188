import React from 'react';

function Features(){
    return <section id="features">
        <div className="container">
            <div className="row">

                <div className="col-lg-4 feature-box">
                    <i className="icon fas fa-heart fa-5x"></i>
                    <h3>Fácil de usar</h3>                    
                    <p>O sistema possui uma interface muito simples e fácil de utilizar.</p>
                </div>

                <div className="col-lg-4 feature-box">
                    <i className="icon fas fa-search fa-5x"></i>
                    <h3>Planejamento é Essencial</h3>
                    <p>Antes de começar a escrever seus casos de teste, tire um momento para planejar sua abordagem.</p>
                </div>

                <div className="col-lg-4 feature-box">
                    <i className="icon fas fa-columns fa-5x"></i>
                    <h3>Documente Suas Decisões</h3>
                    <p>Isso pode ajudar a demonstrar suas habilidades de tomada de decisão.</p>
                </div>
                       
            </div>
        </div>
    </section>;
  }

export default Features;