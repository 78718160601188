import React, { useContext, useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./navbar.css";
import { AuthContext } from "../../Context/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import emailjs from '@emailjs/browser'
import firebase from "../../Config/firebase";

function Navbar() {
  const { setLogado, user } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");
  const [githubLink, setGithubLink] = useState("");
  const [nomeRecrutador, setNomeRecrutador] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const db = firebase.firestore();
  const [permissao, setPermissao] = useState(null);

  useEffect(() => {
    if (user) {
      db.collection("usuarios")
        .doc(user.email)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setPermissao(doc.data().permissao);
          } else {
            history.push("/definirpermissao");
          }
        })
        .catch((error) => {
          console.error("Erro ao verificar a permissão do usuário:", error);
        });
    }
  }, [user, db, history]);

  useEffect(() => {
    const storedEmail = localStorage.getItem("emailSalvo");
    if (storedEmail) {
      setEmail(storedEmail);

      db.collection("usuarios")
        .where("email", "==", storedEmail)
        .where("permissao", "==", "admin")
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            setPermissao("admin");
          } else {
            setPermissao(null);
          }
        })
        .catch((error) => {
          console.error("Erro ao verificar a permissão do email no localStorage:", error);
        });
    }
  }, [db]);

  function Logout() {
    setLogado(false);
    history.push('/app');
    localStorage.removeItem("logado");
    localStorage.removeItem("emailSalvo");
  }

  function handleEnviarClick() {
    const candidatosRef = db.collection("candidatos");

    if (nome && telefone && email && githubLink && nomeRecrutador) {
      const dataAtual = new Date();

      candidatosRef
        .add({
          nome: nome,
          telefone: telefone,
          email: email,
          githubLink: githubLink,
          dataEnvio: dataAtual,
          recrutador: nomeRecrutador,
        })
        .then(() => {
          setSuccessAlert(true);
          setTimeout(() => {
            setSuccessAlert(false);
            fecharModal();
          }, 3000);
        })
        .catch((error) => {
          console.error("Erro ao salvar os dados:", error);
        });
    } else {
      console.log("Campos não preenchidos");
    }
    const templateParams = {
      name: nome,
      email: email
    }

    emailjs.send('service_nnpmket', 'template_ehedpa2', templateParams, 'ycUsVjwQyjnGulmtJ')
    .then((response) => { 
      console.log('Informações enviadas', response.status, response.text)
      setNome('')
      setTelefone('')
      setEmail('')
      setGithubLink('')
      setNomeRecrutador('')
  }, (error) => {
    console.log('Falha no envio das informações', error)
  })
  }

  function mostrarModal() {
    setShowModal(true);
  }

  function mostrarConfirmationModal() {
    setShowConfirmationModal(true);
  }

  function fecharModal() {
    setShowModal(false);
    setShowConfirmationModal(false);
  }

  useEffect(() => {
    if (nome && telefone && email && githubLink && nomeRecrutador) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [nome, telefone, email, githubLink, nomeRecrutador]);

  // Verifica se o usuário está na tela de "Opções de Admin"
  const isOptionsAdminPage = location.pathname === "/app/opcoesadmin";

  return (
    <nav className="navbar fixed-top navbar-expand-md navbar-dark">
      <div className="container-fluid">
        <img 
          src="/Images/Logo_Prime.png"
          alt=""
          height="28"
        />
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav">
            {permissao === "admin" && (
              <li className="navbar-admin">
                <Link
                  to='/app/opcoesadmin'
                  className="nav-link"
                  aria-current="page"
                >
                  Opções de Admin
                </Link>
              </li>
            )}
            <li className="navbar-finalizar">
              <Link
                to="#"
                onClick={mostrarModal}
                className="nav-link logout"
                aria-current="page"
              >
                Finalizar
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {showModal && (
        <div className="modal-overlay">
          {!showConfirmationModal ? (
            <div className="modal-finalizar">
              <FontAwesomeIcon
                icon={faTimes}
                className="close-icon-2"
                onClick={fecharModal}
              />
              {isOptionsAdminPage ? (
                <div>
                  <h6>Clique em Logout para sair do sistema</h6>
                  <div className="modal-buttons">
                    <button
                      type="button"
                      className="btn btn-danger-modal"
                      onClick={Logout}
                    >
                      Logout
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <h6>
                    Para submeter os dados do projeto, clique em 'Enviar'. Se
                    desejar sair, clique em 'Logout'.
                  </h6>
                  <div className="modal-buttons">
                    <button
                      type="button"
                      className="btn btn-danger-modal"
                      onClick={Logout}
                    >
                      Logout
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary-modal"
                      onClick={mostrarConfirmationModal}
                    >
                      Enviar
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="modal-finalizar">
              <FontAwesomeIcon
                icon={faTimes}
                className="close-icon x-fechar"
                onClick={fecharModal}
              />
              <h3>Informações do Candidato</h3>
              <form>
                <div className="mb-3">
                  <label htmlFor="nome" className="form-label">
                    Nome
                  </label>
                  <input
                    type="text"
                    className="form-control-modal"
                    id="nome"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="telefone" className="form-label">
                    Telefone
                  </label>
                  <input
                    type="text"
                    className="form-control-modal"
                    id="telefone"
                    value={telefone}
                    onChange={(e) => setTelefone(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    E-mail
                  </label>
                  <input
                    type="email"
                    className="form-control-modal"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="githubLink" className="form-label">
                    Link do Projeto (Github)
                  </label>
                  <input
                    type="text"
                    className="form-control-modal"
                    id="githubLink"
                    value={githubLink}
                    onChange={(e) => setGithubLink(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="nomeRecrutador" className="form-label">
                    Nome do Recrutador
                  </label>
                  <input
                    type="text"
                    className="form-control-modal"
                    id="nomeRecrutador"
                    value={nomeRecrutador}
                    onChange={(e) => setNomeRecrutador(e.target.value)}
                  />
                </div>
                {successAlert && (
                  <div className="alert alert-success text-center mt-3">
                    As informações foram registradas com sucesso!
                  </div>
                )}
                <div className="modal-buttons">
                  <button
                    type="button"
                    className="btn btn-primary-modal"
                    onClick={() => {
                      if (isFormValid) {
                        handleEnviarClick();
                      }
                    }}
                    disabled={!isFormValid}
                  >
                    Salvar
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      )}
    </nav>
  );
}

export default Navbar;
