import React, { useState, useContext } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import './login.css';
import { AuthContext } from '../Context/auth';

import firebase from '../Config/firebase';
import 'firebase/auth';

function Login() {
  var ano = new Date().getFullYear();

  const [email, setEmail] = useState(localStorage.getItem('emailSalvo') || '');
  const [senha, setSenha] = useState('');
  const [sucesso, setSucesso] = useState('');
  const [voltarParaTelaInicial, setVoltarParaTelaInicial] = useState(false);
  
  const history = useHistory();
  const { setLogado } = useContext(AuthContext);

  function LoginUsuario() {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, senha)
      .then(function (firebaseUser) {
        localStorage.setItem('logado', 'S');
        setLogado(true);
        // Redirecione o usuário após o login bem-sucedido
        history.push('/app/home'); // Importe o history usando 'useHistory'
      })
      .catch(function (error) {
        localStorage.setItem('logado', 'N');
        setLogado(false);
        setSucesso('N');
      });
  }
  

  function alterarEmail(event) {
    const novoEmail = event.target.value;
    setEmail(novoEmail);
    localStorage.setItem('emailSalvo', novoEmail);
  }

  function alterarSenha(event) {
    setSenha(event.target.value);
  }

  function voltarParaTelaInicialHandler() {
    setVoltarParaTelaInicial(true);
  }

  if (voltarParaTelaInicial) {
    return <Redirect to="/" />;
  }

  return (
    <div className="d-flex align-items-center text-center form-container">
      <button className="btn float-right mt-2 btn-close" onClick={voltarParaTelaInicialHandler}>
        X
      </button>
      <form className="form-signin">
        <img className="mb-4 imglogo" src="Images/logo_prime_small.png" alt="" />
        <h1 className="h3 mb-3 fw-normal">Login</h1>

        <div className="form-floating">
          <input onChange={alterarEmail} value={email} type="email" className="form-control" id="floatingInput" placeholder="E-mail" />
          <label htmlFor="floatingInput">E-mail</label>
        </div>

        <div className="form-floating">
          <input onChange={alterarSenha} type="password" className="form-control" id="floatingPassword" placeholder="Senha" />
          <label htmlFor="floatingPassword">Senha</label>
        </div>

        <button onClick={LoginUsuario} className="w-100 btn btn-lg btn-primary" type="button">
          Acessar
        </button>

        {sucesso === 'N' ? <div className="alert alert-danger mt-2" role="alert">E-mail ou senha inválida.</div> : null}
        
        <div className="login-links mt-5">
          <Link to="/app/resetsenha" className="mx-3">
            Esqueci minha senha
          </Link>
          <Link to="/app/novaconta" className="mx-3">
            Criar uma conta
          </Link>
        </div>

        <p className="mt-5 mb-3 text-muted">&copy; Desenvolvido por Prime Control - {ano}</p>
      </form>
    </div>
  );
}

export default Login;
