import React from 'react';

function Footer(){
  var ano = new Date().getFullYear(); 

    return <section id="footer">    
      
      <footer>
        <div>
              <ul className="list-unstyled list-inline social text-center">
                  <li className="list-inline-item"><a href="https://www.linkedin.com/company/prime-control/" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin fa-2x"></i></a></li>
                  <li className="list-inline-item"><a href="https://www.facebook.com/primecontrol/" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook fa-2x"></i></a></li>
                  <li className="list-inline-item"><a href="https://www.instagram.com/primecontrol_br/" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram fa-2x"></i></a></li>						
                  <li className="list-inline-item"><a href="https://twitter.com/PrimeControl" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter fa-2x"></i></a></li>
                  <li className="list-inline-item"><a href="https://www.youtube.com/channel/UCf6cnRxQwhr_tu0RW5nugBQ" target="_blank" rel="noopener noreferrer"><i className="fa fa-youtube fa-2x"></i></a></li>
              </ul>
        </div>
      
        <small>&copy; Copyright {ano}, <a href="https://www.primecontrol.com.br/" target="_blank" rel="noopener noreferrer">Prime Control Consultoria</a></small>
      </footer>
      
      {/* <p>Desenvolvido por Prime Control - {ano}</p>
      <copiryght></copiryght> */}
    </section>;
  }

export default Footer;