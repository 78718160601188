import React from 'react';
import Menu from './Components/menu';
import Banner from './Components/banner';
import Features from './Components/features';
import Sobre from './Components/sobre';
// import Desafios from './Components/desafios';
import Footer from './Components/footer';

function Site(){
    return <div>
      <Menu/>
      <Banner/>
      <Features/>
      <Sobre/>
      {/* <Desafios/> */}
      <Footer/>
    </div>;
  }

export default Site;