import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AuthProvider } from './app/Context/auth.jsx';


/* Paginas */
import Site from './site/site.jsx';
import Login from './app/Login/login';
import NovaConta from './app/NovaConta/novaconta';
import ResetSenha from './app/ResetSenha/resetsenha';
import Home from './app/Home/home';
import NovoCliente from './app/NovoCliente/novocliente';
import EditarCliente from './app/EditarCliente/editarcliente';
import OpcoesAdmin from './app/Components/OpcoesAdmin/OpcoesAdmin';
import PrivateRoute from './app/Components/PrivateRoute/PrivateRoute'

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Site} />
          <Route exact path="/app" component={Login} />
          <Route exact path="/app/novaconta" component={NovaConta} />
          <Route exact path="/app/resetsenha" component={ResetSenha} />
          <Route exact path="/app/editarcliente/:id" component={EditarCliente} />
          <Route exact path="/app/novocliente" component={NovoCliente} />

          <PrivateRoute exact path="/app/home" component={Home} />
          <PrivateRoute exact path="/app/opcoesadmin" component={OpcoesAdmin} />
        </Switch>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
